<template>
  <v-layout class="mb-4 pb-4" wrap>
    <v-flex xs12>
      <Title title="Notificaciones Push" subtitle=""></Title>
    </v-flex>
    <v-flex xs12>
      <v-card>
        <v-card-text>
          <v-layout class="py-2 px-2 border-bottom" wrap align-start row>
            <v-flex xs12 sm4 class="body-2">
              Interes o Intereses
            </v-flex>
            <v-flex xs12 sm8>
              <v-select v-model="item.interest" :items="items" :label="'Listado de intereses'" class="mb-4" multiple>
              </v-select>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Titulo
            </v-flex>
            <v-flex xs12 sm8>
              <v-text-field v-model="item.title" label="Titulo" type="text"></v-text-field>
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Description
            </v-flex>
            <v-flex xs12 sm8>
              <v-text-field v-model="item.description" label="Descripcion" type="text"></v-text-field>
            </v-flex>
            <v-flex xs12 class="my-2">
              <v-divider style="opacity:0.3"></v-divider>
            </v-flex>
            <v-flex xs12 sm4 class="body-2">
              Url
            </v-flex>
            <v-flex xs12 sm8>
              <v-text-field v-model="item.url" label="Url" type="text"></v-text-field>
            </v-flex>
          </v-layout>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium color="accent" :loading="loading" :disabled="loading" flat @click="update">
            Enviar Notificaciones
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { CONFIG } from '../../config'
import Title from '../useful/title.vue'

export default {
  name: 'SettingCategories',
  components: {
    Title
  },
  data() {
    return {
      loading: false,
      item: {
        title: '',
        description: '',
        url: '',
        interest: []
      },
      items: []
    }
  },
  mounted() {
    this.getCMS()
  },
  methods: {
    async getCMS() {
      try {
        const res = await this.$http.get(`${CONFIG}/interests`)
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async update() {
      await this.$http.put(`${CONFIG}/interests`, this.item)
    }
  }
}
</script>
